import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import {
    Col,
    Form,
    Card,
    CardBody,
    Label,
    Row,
    TabPane,
    Media,
    NavItem,
    NavLink,
    Nav,
    Button
} from "reactstrap";
import axios from 'axios';
import seventeen from "../../../../../assets/images/logos/17.png";
import { useHistory } from 'react-router';

const AddCompany = () => {

    const [activeTab, setActiveTab] = useState("1");
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }
    const [errorlist, setError] = useState([]);


    const [companyInput, setCompany] = useState({
        bussiness_en: '',
        bussiness_es: '',
        image_bussiness: '',
        mision_en: '',
        mision_es: '',
        image_mision: '',
        vision_en: '',
        vision_es: '',
        image_vision: '',
        values_en: '',
        values_es: '',
        image_values: '',
    });
    const history = useHistory();
    
    const [pricture, setPicture] = useState([]);
    const [pricture2, setPicture2] = useState([]);
    // const [pricture3, setPicture3] = useState([]);
    const [pricture4, setPicture4] = useState([]);


    const handleInput = (e) => {
        e.persist();
        setCompany({ ...companyInput, [e.target.name]: e.target.value })

    }

    const handleImage = (e) => {
        e.persist();
        setPicture({ image_bussiness: e.target.files[0] });

    }

    const handleImage2 = (e) => {
        e.persist();
        setPicture2({image_mision: e.target.files[0] });
    }

    const handleImage4 = (e) => {
        e.persist();
        setPicture4({image_values: e.target.files[0] });
    }
    // const handleImage3 = (e) => {
    //     e.persist();
    //     setPicture3({image3: e.target.files[0] });
    // }

    useEffect(() => {
    }, []);

    const submitCompany = (e) => {
        e.preventDefault();

        
        const formData = new FormData();
        formData.append('image_bussiness', pricture.image_bussiness);
        formData.append('image_mision', pricture2.image_mision);
        formData.append('image_vision', pricture2.image_mision);
        formData.append('image_values', pricture4.image_values);
        formData.append('bussiness_en', companyInput.bussiness_en);
        formData.append('bussiness_es', companyInput.bussiness_es);
        formData.append('mision_en', companyInput.mision_en);
        formData.append('mision_es', companyInput.mision_es);
        formData.append('vision_en', companyInput.vision_en);
        formData.append('vision_es', companyInput.vision_es);
        formData.append('values_en', companyInput.values_en);
        formData.append('values_es', companyInput.values_es);

        axios.post(`/api/store-company`, formData).then(res => {

            if (res.data.status === 200) {
                swal("Company Fue Creado", "", "success");

                history.push('/dashboard')
                setCompany({
                    ...companyInput,
                    bussiness_en: '',
                    bussiness_es: '',
                    image_bussiness: '',
                    mision_en: '',
                    mision_es: '',
                    image_mision: '',
                    vision_en: '',
                    vision_es: '',
                    image_vision: '',
                    values_en: '',
                    values_es: '',
                    image_values: '',
                });
                setError([]);
            }
            else if (res.data.status === 400) {
                setError(res.data.errors);
            }

        });

    }


    return (
        <section className="dashboard-section section-b-space">
            <Row>
                <Col lg="3">
                    <div className="dashboard-sidebar">
                        <div className="profile-top">
                            <div className="profile-image">
                                <Media src={seventeen} alt="" className="img-fluid" />
                            </div>
                            <div className="profile-detail">
                                <a href="/#/">
                                    <h5>Lordon</h5>
                                </a>

                                <br /><br />
                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                </NavItem>

                            </div>
                        </div>
                        <div className="faq-tab">
                            <Nav tabs className="border-tab-primary">
                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                    <NavLink
                                        className={activeTab === "1" ? "active" : ""}
                                        onClick={() => setActiveTab("1")}
                                    >
                                        Agregar información de la compañia
                                    </NavLink>
                                </NavItem>

                                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                    <NavLink
                                        href="/#/dashboard/"
                                    >
                                        Volver
                                    </NavLink>
                                </NavItem>

                            </Nav>
                        </div>
                    </div>
                </Col>
                <Col lg="9">
                    <TabPane tabId="3">
                        <Col sm="12">
                            <Card className="dashboard-table mt-0">
                                <CardBody>
                                    <div className="top-sec">
                                        <h3>Agregar Company</h3>
                                    </div>
                                </CardBody>
                                <CardBody>

                                    <Form onSubmit={submitCompany} encType="multipart/form-data">
                                        (Ingles)
                                        <Row>
                                            <Col md="12" lg="12" sm="12">
                                                <Label>
                                                    {/* ¿Qué hacemos? */}
                                                    Our core business
                                                </Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-bars prefix"></i>
                                                        </span>
                                                    </div>

                                                    <textarea
                                                        className="form-control"
                                                        name="bussiness_en"
                                                        id="validationCustom01"
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={companyInput.bussiness_en}
                                                        maxLength="500"
                                                        
                                                    ></textarea>
                                                </div>
                                                <small className="text-danger">{errorlist.name}</small>

                                            </Col>

                                            <Col md="12" lg="12" sm="12">
                                                <Label >
                                                    Mision
                                                </Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-random prefix"></i>
                                                        </span>
                                                    </div>


                                                    <textarea
                                                        className="form-control"
                                                        name="mision_en"
                                                        id="validationCustom01"
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={companyInput.mision_en}
                                                        maxLength="500"
                                                        
                                                    ></textarea>
                                                </div>
                                                <small className="text-danger">{errorlist.slug}</small>

                                            </Col>

                                            <Col md="12" lg="12" sm="12">
                                                <Label >
                                                    Vision
                                                </Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-random prefix"></i>
                                                        </span>
                                                    </div>


                                                    <textarea
                                                        className="form-control"
                                                        name="vision_en"
                                                        id="validationCustom01"
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={companyInput.vision_en}
                                                        maxLength="500"
                                                        
                                                    ></textarea>
                                                </div>
                                                <small className="text-danger">{errorlist.slug}</small>

                                            </Col>

                                            <Col md="12" lg="12" sm="12">
                                                <Label >
                                                    Values
                                                </Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-random prefix"></i>
                                                        </span>
                                                    </div>


                                                    <textarea
                                                        className="form-control"
                                                        name="values_en"
                                                        id="validationCustom01"
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={companyInput.values_en}
                                                        maxLength="500"
                                                        
                                                    ></textarea>
                                                </div>
                                                <small className="text-danger">{errorlist.slug}</small>

                                            </Col>


                                        </Row>
                                        <br />

                                        {/* (Español)
                                        <Row>
                                        <Col md="12" lg="12" sm="12">
                                                <Label>
                                                    ¿Qué hacemos?
                                                </Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-bars prefix"></i>
                                                        </span>
                                                    </div>

                                                    <textarea
                                                        className="form-control"
                                                        name="bussiness_es"
                                                        id="validationCustom01"
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={companyInput.bussiness_es}
                                                        maxLength="30"
                                                        
                                                    ></textarea>
                                                </div>
                                                <small className="text-danger">{errorlist.name}</small>

                                            </Col>

                                            <Col md="12" lg="12" sm="12">
                                                <Label >
                                                    Misión
                                                </Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-random prefix"></i>
                                                        </span>
                                                    </div>


                                                    <textarea
                                                        className="form-control"
                                                        name="mision_es"
                                                        id="validationCustom01"
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={companyInput.mision_es}
                                                        maxLength="500"
                                                        
                                                    ></textarea>
                                                </div>
                                                <small className="text-danger">{errorlist.slug}</small>

                                            </Col>

                                            <Col md="12" lg="12" sm="12">
                                                <Label >
                                                    Visión
                                                </Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-random prefix"></i>
                                                        </span>
                                                    </div>


                                                    <textarea
                                                        className="form-control"
                                                        name="vision_es"
                                                        id="validationCustom01"
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={companyInput.vision_es}
                                                        maxLength="500"
                                                        
                                                    ></textarea>
                                                </div>
                                                <small className="text-danger">{errorlist.slug}</small>

                                            </Col>

                                            <Col md="12" lg="12" sm="12">
                                                <Label >
                                                    Valores
                                                </Label>

                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-random prefix"></i>
                                                        </span>
                                                    </div>


                                                    <textarea
                                                        className="form-control"
                                                        name="values_es"
                                                        id="validationCustom01"
                                                        type="text"
                                                        onChange={handleInput}
                                                        value={companyInput.values_es}
                                                        maxLength="500"
                                                        
                                                    ></textarea>
                                                </div>
                                                <small className="text-danger">{errorlist.slug}</small>

                                            </Col>
                                        </Row> */}

                                        (Imagenes)
                                        <Row>
                                            <Col md="6" lg="6" sm="12">
                                                <Label >
                                                    ¿Qué hacemos?
                                                </Label>
                                                <input

                                                    className="form-control  center input-bordered"
                                                    name="image_bussiness"
                                                    id="validationCustom01"
                                                    type="file"
                                                    onChange={handleImage}
                                                ></input>
                                                <small className="text-danger">{errorlist.image_bussiness}</small>
                                            </Col>

                                            <Col md="6" lg="6" sm="12">
                                                <Label >
                                                    Mision y Vision
                                                </Label>
                                                <input

                                                    className="form-control  center input-bordered"
                                                    name="image_mision"
                                                    id="validationCustom01"
                                                    type="file"
                                                    onChange={handleImage2}
                                                ></input>
                                                <small className="text-danger">{errorlist.image_mision}</small>
                                            </Col>
                                            
                                            {/* <Col md="6" lg="6" sm="12">
                                                <Label >
                                                    Imagen Vision
                                                </Label>
                                                <input

                                                    className="form-control  center input-bordered"
                                                    name="image3"
                                                    id="validationCustom01"
                                                    type="file"
                                                    onChange={handleImage3}
                                                ></input>
                                                <small className="text-danger">{errorlist.image}</small>
                                            </Col> */}

                                            <Col md="6" lg="6" sm="12">
                                                <Label >
                                                    Valores
                                                </Label>
                                                <input

                                                    className="form-control  center input-bordered"
                                                    name="image_values"
                                                    id="validationCustom01"
                                                    type="file"
                                                    onChange={handleImage4}
                                                ></input>
                                                <small className="text-danger">{errorlist.image_values}</small>
                                            </Col>

                                            

                                        </Row>
                                        <br />

                                        <div className="offset-xl-5 offset-sm-4">
                                            <Button className="btn btn-solid" type="submit"
                                            >
                                                Añadir
                                            </Button>
                                        </div>
                                    </Form>

                                </CardBody>
                            </Card>
                        </Col>
                    </TabPane>
                </Col>
            </Row>
        </section>
    )

}

export default AddCompany;