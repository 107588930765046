import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,

} from "reactstrap";
import { urlApi } from "../../../../../config";

const CompanyList = () => {
    const [loading, setLoading] = useState(true);
    const [companylist, setCompanylist] = useState([]);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        axios.get(`/api/view-company`).then(res => {
            //console.log(res.data.category);
            if(res.status === 200)
            {
               setCompanylist(res.data.company)
            }
           setLoading(false);

        });
    }, []);

    const deleteCompany = (e, id) => {

        e.preventDefault();
        const thisClicked = e.currentTarget;

        axios.delete(`/api/delete-company/${id}`).then(res => {
            if(res.data.status === 200)
            {
               swal("Company Eliminado","","success");
               thisClicked.closest("tr").remove();
            }else if (res.data.status === 404) {
                swal("Company Eliminado",res.data.message,"error");
            }

        });

    }
    var viewcompany_HTMLTABLE = "";
    if (loading) {

        return <h4>Loading Companys</h4>
        
    }else{
        viewcompany_HTMLTABLE =
        companylist.map( (item) => {
            return(
                <tr key={item.id}>
                    
                    <td>{item.bussiness_en}</td>
                    <td>{item.mision_en}</td>
                    <td>{item.vision_en}</td>
                    <td>{item.values_en}</td>

                    <td>
                    <Link to={`/updatecompany/${item.id}`} target="_blank">
                  <a>
                    <i className="fa fa-pencil btn-solid btn-xs" aria-hidden="true"></i>
                  </a>
                </Link>
                    </td>
                 {/* {companylist.length === 1 ? <td>
                        <button type="button" disabled={disabled} onClick={() => setDisabled(true)} className="fa fa-trash btn-solid btn-xs"></button>
                    </td>:
                    <td>
                        <button type="button" onClick={ (e) => deleteCompany(e, item.id) } className="fa fa-trash btn-solid btn-xs"></button>
                    </td>
                 } */}
                </tr>
            )
        });
    }

    return (
        <Row>
            <Col sm="12">
                <table className="table mb-0 table-responsive-sm">
                    <thead>
                        <tr>
                            <th scope="col">Acerca de la Compañia</th>
                            <th scope="col">Mision</th>
                            <th scope="col">Vision</th>
                            <th scope="col">Valores</th>
                        </tr>
                    </thead>
                  <tbody>
                      {viewcompany_HTMLTABLE}
                  </tbody>
                </table>
            </Col>
        </Row>
    )

}

export default CompanyList;