import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from "reactstrap";
import ReactGa from 'react-ga';

const ClickHandler = () => {
  ReactGa.event({
    category: 'Categoria',
    action: 'Click'
  })
}

const MasterBanner = ({ img, title, desc, link, classes, btn, btnClass, titleone }) => {
  return (
    <div>
      <div style={{ backgroundImage: `url(${img})` }} className={`home ${img} ${classes ? classes : "text-center"}`}>
      {/* <div className={`home ${img} ${classes ? classes : "text-center"}`}> */}
        <Container>
          <Row>
            <Col>
              <div>
                <div>
                  {/* <h4 className="text-white text-banner-shadow"><span className="text-banner">{titleone}</span>{title}</h4>                   */}
                  {/* <h1>{desc}</h1> */}
                  <Link to={`/viewfullcategory/${link}`}>
                    <Button onClick={ClickHandler} style={{ marginTop: '23%'}} className="text-blue"><span>{'See more ...'}</span></Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MasterBanner;
