import React, { Fragment, useState, useEffect } from "react";
import Slider from "react-slick";
import Link from "next/link";
import { Container, Row, Col } from "reactstrap";
import MasterBanner from "./MasterBanner";
import "../../../../assets/scss/app.scss";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import ReactGa from 'react-ga';

const Banner = () => {
  const [t, i18n] = useTranslation("global");
  //const Data = [];
  const Data = [
    {
      img: "home1",
      titleone: `${t("header-one.banner")}`,
      title: `${t("header-one.bannerb")}`,
      desc: `${t("header-one.banner1")}`,
      link: "product-details/right_sidebar",
    },
    {
      img: "home2",
      titleone: `${t("header-one.banner2")}`,
      title: `${t("header-one.banner2b")}`,
      desc: `${t("header-one.banner2c")}`,
      link: "product-details/right_sidebar",
    },
  ];
  
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState([]);
  
  useEffect(() => {
    let isMountered = true;

    axios.get(`/api/view-banner`).then(res => {
      if (isMountered) {

        if (res.data.status === 200) {
          setBanner(res.data.banner);
          setLoading(false);
        }
      }
    });
    return () => {
      isMountered = false;
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Cambia el tiempo de cambio de slide aquí (en milisegundos)
  };


  return (
    <Fragment>
      <section className="p-0">
        <Slider {...settings} className="slide-1 home-slider">
          {
            banner.length > 0 ? 
              banner.map((data, i) => {
                return (
                  <MasterBanner
                    key={i}
                    img={`https://lordon.com.mx:81/${data.image}`}
                    title={''}
                    titleone={''}
                    link={data.category.slug}
                  />
                );
              })
            :
            Data.map((data, i) => {
              return (
                <MasterBanner
                  key={i}
                  img={data.img}
                  //desc={data.desc}
                  title={data.title}
                  titleone={data.titleone}
                  link={data.link}
                />
              );
            })
          }
        </Slider>
      </section>
    </Fragment>
  );
};

export default Banner;