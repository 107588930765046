import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Collapse,
} from "reactstrap";
import face from "../../../assets/images/face.jpg";
import insta from "../../../assets/images/instagram.png";
import tweet from "../../../assets/images/tweet.png";
import CopyRight from "./copyright";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/icon/logo1.png";
import { animateScroll as scroll } from "react-scroll";

const MasterFooter = ({
  containerFluid,
  logoName,
  layoutClass,
  footerClass,
  footerLayOut,
  footerSection,
  belowSection,
  belowContainerFluid,
  copyRightFluid,
  newLatter,
}) => {
  const [t, i18n] = useTranslation("global");
  const width = window.innerWidth < 750;

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div>
      <footer className={footerClass}>

        <section className={belowSection}>
          <Container fluid={belowContainerFluid ? belowContainerFluid : ""}>
            <Row className="footer-theme partition-f button-tp">
              <Col lg="4" md="6">
                <div
                  className={`footer-title ${window.innerWidth < 750 ? "" : "active"
                    } footer-mobile-title`}
                >
                  <div className="sub-title">
                  <Link to='/#/business/'>
                  <h4
                    className="h4-search"
                  >
                    
                    
                  </h4>
                  </Link>
                  </div>
                </div>
                  <div className="footer-contant">
                    <div className="footer-logo">
                    <Link to='/'>
                      <img src={logo} alt="" className="phone img-fluid"/>
                      </Link>
                    </div>
                    
                    {/* <div className="footer-social">
                      <ul>
                      <li>
                          <a href="https://api.whatsapp.com/send?phone=52123456789&text=Hola%20me%20gustaria%20saber%20mas%20informacion%20..." target="_blank">
                          <img
                            src={face} width="48" className="phone img-fluid"/>
                          </a>
                        </li>

                        <li>
                          <a href="https://www.facebook.com" target="_blank">
                          <img
                            src={insta} width="48" className="phone img-fluid"/>
                          </a>
                        </li>
              
                        <li>
                          <a href="https://www.instagram.com" target="_blank">
                          <img
                            src={tweet} width="48" className="phone img-fluid"/>
                          </a>
                        </li>

                      </ul>

                    </div> */}
              
                      {/*<h2 className="text-red">Síguenos</h2>*/}
                  </div>
              </Col>
              <Col>
                <div className="sub-title">
                  <div
                    className={`footer-title ${window.innerWidth < 750 ? "" : "active"
                      } `}
                  >
                    <h4
                      className="h4-search"
                    >
                      {t("footer.footer1")}
                      
                    </h4>
                  </div>
                  <div className="sub-title">
                      <div className="footer-contant">
                        <ul className="contact-list">
                        
                          <li>
                              <i className="fa fa-share"></i>
                              <a onClick={scrollToTop}>                        
                              <i></i>{t("footer.back-text")}
                        
                              </a>
                          </li>
                          <li>
                            <i className="fa fa-whatsapp"></i>{t("footer.phone")} :{" "}
                            <a href="https://api.whatsapp.com/send?phone=525565207893&text=Hola%20me%20gustaria%20saber%20mas%20informacion%20..." target="_blank">+52 55 6520 7893</a>
                            
                          </li>
                          <li>
                            <i className="fa fa-envelope-o"></i>{t("footer.email")} :{" "}
                            <a href="mailto:info@lordon.com.mx" target="_blank">info@lordon.com.mx</a>
                          </li>
                          <li>
                            <i className="fa fa-plus"></i>{t("footer.hour")} :{" "}
                            <i></i>{t("footer.hour-text")}
                          </li>

                        </ul>
                      </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="sub-title">
                  <div
                    className={`footer-title ${window.innerWidth < 750 ? "" : "active"
                      } `}
                  >
                    <h4 className="h4-search">
                      {t("footer.footer2")}
                      
                    </h4>
                  </div>
                    <div className="footer-contant">
                      <ul className="contact-list">
                        <li>
                          <i className="fa fa-map-marker"></i>Calle 9 No 118, Col. Ignacio Zaragoza, Venustiano Carranza, CP.15000, Ciudad de
                          México, México
                        </li>
                      </ul>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="sub-title">
                      <div
                        className={`footer-title ${window.innerWidth < 750 ? "" : "active"
                          } `}
                      >
                        <h4 className="h4-search">
                          {t("footer.footer3")}
                          
                        </h4>
                      </div>
                        <div className="footer-contant">
                          <ul className="contact-list">
                          <li>
                                <i className="fa fa-random"></i>
                                <a href="https://lordon.com.mx/PrivacyAdvise.pdf">https://avisodeprivacidad</a>
                              </li>
                          </ul>
                        </div>
                    </div>
    
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <CopyRight
          layout={layoutClass}
          fluid={copyRightFluid ? copyRightFluid : ""}
        />
      </footer>
    </div>
  );
};
export default MasterFooter;
