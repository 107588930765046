import React, { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import { Container, Row, Col, Media, Button } from "reactstrap";
import mision from '../../../../../assets/images/mision.png';
import vision from '../../../../../assets/images/vision.png';
import quesomos from '../../../../../assets/images/quesomos.png';
import quienessomos from '../../../../../assets/images/quienessomos.png';
import user1 from '../../../../../assets/images/comments/user1.png';
import user2 from '../../../../../assets/images/comments/user2.png';
import Slider from "react-slick";
import { Slider2 } from "../../../../../services/script";
import { useTranslation } from "react-i18next";
import axios from 'axios';

const DescriptionBusiness = ({ sectionClass, title, inner, hrClass }) => {
    const [t, i18n] = useTranslation("global");
    const imgData = [
        user1,
        user2,
    ];

    const [company, setCompany] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMountered = true;

        axios.get(`/api/view-company`).then(res => {
            if (isMountered) {
                if (res.data.status === 200) {
                    setCompany(res.data.company[0]);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMountered = false;
        }
    }, []);


    if (loading) {
        return (
            <Container className="col-md-2">
                <Row>
                    <h4 className='button-center'>Loading...</h4>
                </Row>
            </Container>
        )
   
        
    }
    else
    {
        return (
            <Fragment>
                <section className="bg-body">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="text-center">
                                    <h2 className="text-blue-ford">{t("business.business2")}</h2>
    
                                </div>
    
    
                            </Col>
                        </Row>
    
    
                    </Container>
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="description-business">
                                    <div className="left-content-business">
                                        <div className="left-text-container">
                                            <h2 className="text-red box-p">{t("business.business4")}</h2>
                                            <div className="product-para">
                                            <p className="text-blue box-p2">
                                                {company.bussiness_en}
                                                {/* {t("business.business5")}
                                                <span className="text-blue">{t("business.business55")}</span>
                                                <span className="text-blue">{t("business.business555")}</span> */}
                                            </p>
                                            </div>
    
                                        </div>
                                    </div>
    
    
                                    <div className="right-content">
                                        <img src={`https://lordon.com.mx:81/${company.image_bussiness}`} alt="" className="phone image-fluid" />
                                    </div>
                                </div>
    
                            </Col>
                        </Row>
                    </Container>
                    <br></br>
                    <br></br>
                    <Container>
                        <Row>
                            <Col md="12" className="only-pc">
                                
                                <div className="description-business">
                                    <div className="left-content-business">
                                        
                                                             
                                        <div className="left-text-container">
                                            <div className="right-content">
                                                <img src={`https://lordon.com.mx:81/${company.image_mision}`} alt="" className="phone image-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="left-text-container">
                                        
                                    </div> */}
                                    <div>
                                        <h2 className="text-red box-p">{t("business.business7")}</h2>
                                        <div className="product-para">
                                        <p className="text-blue box-p2">
                                            {company.mision_en}
                                            {/* {t("business.business8")} */}
                                        </p>
                                        </div>
    
                                        <h2 className="text-red box-p">{t("business.business14")}</h2>
                                        <div className="product-para">
                                        <p className="text-blue box-p2">
                                            {company.vision_en}
                                            {/* {t("business.business15")} */}
                                        </p>
                                        </div>
                                        
                                    </div>
                                </div>
    
    
                            </Col>
                            <Col md="12" className="only-phone">
    
                                <div className="description-business">
                                    <div className="left-content-business">
                                        <div className="left-text-container">
                                        <h2 className="text-red box-p">{t("business.business7")}</h2>
                                        <div className="product-para">
                                            <p className="text-blue box-p2">
                                                {company.mision_en}
                                                {/* {t("business.business8")} */}
                                            </p>
                                            </div>
    
                                        </div>
                                    </div>
    
                                    <div className="left-text-container">
                                        <h2 className="text-red box-p">{t("business.business14")}</h2>
                                        <div className="product-para">
                                        <p className="text-blue box-p2">
                                            {company.vision_en}
                                            {/* {t("business.business15")} */}
                                        </p>
                                        </div>
                                    </div>
    
                                    {/* <div className="right-content"> ocultamos en movil
                                    <img src={mision} alt="" className="phone image-fluid" />
                                    </div> */}
                                </div>
    
    
                            </Col>
                        </Row>
                    </Container>
                    <br></br>
                    <br></br>
                    <Container>
                    <Row>
                        <Col md="12" className="only-pc">
                            <div className="description-business-new">
                                <div className="left-content-business">
                                    
                                </div>
    
                            
                                
                            </div>
    
    
                        </Col>
                        <Col md="12" className="only-phone">
    
                            <div className="description-business">
                                {/* <div className="left-content-business">
                                    <div className="left-text-container">
                                    <h2 className="text-red box-p">{t("business.business14")}</h2>
                                    <div className="product-para">
                                        <p className="text-blue box-p2">
                                            {t("business.business15")}
                                        </p>
                                        </div>
    
                                    </div>
                                </div> */}
    
    
                                <div className="right-content">
                                <img src={`https://lordon.com.mx:81/${company.image_mision}`} alt="" className="phone image-fluid" />
                                </div>
                            </div>
    
    
                            </Col>
                        </Row>
                        
                    </Container>
    
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="description-business">
                                    <div className="left-content-business">
                                        <div className="left-text-container">
                                            <h2 className="text-red box-p">{t("business.business9")}</h2>
                                            <div className="product-para">
                                            <p className="text-blue box-p2 ">
                                                {company.values_en}
                                                {/* {t("business.business10")}
                                                {t("business.business16")}
                                                {t("business.business17")} */}
                                            </p>
                                            </div>
                                        </div>
                                    </div>
    
    
                                    <div className="right-content">
                                        <img src={`https://lordon.com.mx:81/${company.image_values}`} alt="" className="phone image-fluid" />
                                    </div>
                                    {/* <h4 className="business-img-txt">{t("header-one.businesst")}</h4>
                                    <h4 className="business-img-txt-two">{t("header-one.businesst1")}<br/>{t("header-one.businesst11")}</h4>
                                    <h4 className="business-img-txt-three">{t("header-one.businesst2")}</h4>
                                    <h4 className="business-img-txt-forth">{t("header-one.businesst3")}</h4>
                                    <h4 className="business-img-txt-five">{t("header-one.businesst4")}<br/>{t("header-one.businesst44")}</h4>
                                    <h4 className="business-img-txt-six">{t("header-one.businesst5")}</h4> */}
                                </div>
                                <br></br>
                                <br></br>
    
                            </Col>
                        </Row>
                        
                    </Container>
                </section>
            </Fragment>
    
    
    
        );
    }
    
};

export default DescriptionBusiness;
