import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import {
    Col,
    Form,
    Card,
    CardBody,
    Label,
    Container,
    Row,
    TabPane,
  Media,
  NavItem,
  NavLink,
  Nav,
  Button
} from "reactstrap";
import seventeen from "../../../../../assets/images/logos/17.png";
import axios from 'axios';
import { useHistory } from 'react-router';
import { urlApi } from "../../../../../config";

const UpdateCompany = (props) => {

  const [activeTab, setActiveTab] = useState("1");
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  }

    const [loading, setLoading] = useState(true);

    const [pricture, setPicture] = useState([]);
    const [pricture2, setPicture2] = useState([]);
    // const [pricture3, setPicture3] = useState([]);
    const [pricture4, setPicture4] = useState([]);

    const history = useHistory();
    const [companyInput, setCompany] = useState([]);
    const [error, setError] = useState([]);

    const handleImage = (e) => {
        e.persist();
        setPicture({ image_bussiness: e.target.files[0] });

    }

    const handleImage2 = (e) => {
        e.persist();
        setPicture2({image_mision: e.target.files[0] });
    }
    
    const handleImage4 = (e) => {
        e.persist();
        setPicture4({image_values: e.target.files[0] });
    }
       

    useEffect(() => {
        
        const company_id = props.match.params.id;
        axios.get(`/api/edit-company/${company_id}`).then(res => {

            if(res.data.status === 200)
            {
                setCompany(res.data.company);
            }
            else if(res.data.status === 404)
            {
                swal("Error",res.data.message,"error");
                history.push('/dashboard')
            }

            setLoading(false);

        });

    }, [props.match.params.id, history]);

    const handleInput = (e) => {
        e.persist();
        setCompany({...companyInput, [e.target.name]: e.target.value})

    }

    const updateSCategory = (e) => {
        e.preventDefault();
        const company_id = props.match.params.id;
        const formData = new FormData();
        formData.append('image_bussiness', pricture.image_bussiness);
        formData.append('image_mision', pricture2.image_mision);
        formData.append('image_vision', pricture2.image_mision);
        formData.append('image_values', pricture4.image_values);
        formData.append('bussiness_en', companyInput.bussiness_en);
        formData.append('bussiness_es', companyInput.bussiness_es);
        formData.append('mision_en', companyInput.mision_en);
        formData.append('mision_es', companyInput.mision_es);
        formData.append('vision_en', companyInput.vision_en);
        formData.append('vision_es', companyInput.vision_es);
        formData.append('values_en', companyInput.values_en);
        formData.append('values_es', companyInput.values_es);
    
        axios.post(`/api/update-company/${company_id}`, formData).then(res => {
            if(res.data.status === 200)
            {
                swal("Company Editado","","success");
                history.push('/dashboard')
            }else if (res.data.status === 422) {
                swal("Todos Los Campos Son Obligatorios","","error");
                setError(res.data.errors);
            }else if(res.data.status === 404) {
                swal("Error",res.data.message,"error");
                history.push('category/CompanyList')
            }

        });

    }

    if (loading) {

        return <Container className="col-md-2">
        <Row><h4 className='button-center'>Cargando Company...</h4>
        </Row>
    </Container>
       
            
        }


    return (
        <section className="dashboard-section section-b-space">
        <Row>
            <Col lg="3">
          <div className="dashboard-sidebar">
            <div className="profile-top">
              <div className="profile-image">
                <Media src={seventeen} alt="" className="img-fluid" />
              </div>
              <div className="profile-detail">
              <a href="/#/"> 
              <h5>Lordon</h5>
              </a>

                <br /><br />
                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                </NavItem>

              </div>
            </div>
            <div className="faq-tab">
              <Nav tabs className="border-tab-primary">
                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    Editar Company
                  </NavLink>
                </NavItem>
      
                <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                  <NavLink
                    href="/#/dashboard/"
                  >
                    Volver
                  </NavLink>
                </NavItem>

              </Nav>
            </div>
          </div>
        </Col>
        <Col lg="9">
        <TabPane tabId="3">
            <Col sm="12">
                <Card className="dashboard-table mt-0">
                    <CardBody>
                        <div className="top-sec">
                            <h3>Editar Company</h3>
                        </div>
                    </CardBody>
                    <CardBody>

                        <Form onSubmit={updateSCategory} encType="multipart/form-data">
                            {/* <Row>
                                <Col md="6" lg="6" sm="12">
                                    <Label>
                                        Nombre Del Company
                                    </Label>

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon">
                                                <i className="fa fa-bars prefix"></i>
                                            </span>
                                        </div>

                                        <input
                                            className="form-control"
                                            name="name"
                                            id="validationCustom01"
                                            type="text"
                                            onChange={handleInput}
                                            value={companyInput.name}
                                            maxLength="30"
                                            placeholder="Ingrese nombre"
                                        ></input>
                                    </div>

                                </Col>

                                <Col md="6" lg="6" sm="12">
                                    <Label >
                                        Link Del Company
                                    </Label>

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon">
                                                <i className="fa fa-random prefix"></i>
                                            </span>
                                        </div>

                                        
                                        <input
                                            className="form-control"
                                            name="slug"
                                            id="validationCustom01"
                                            type="text"
                                            onChange={handleInput}
                                            value={companyInput.slug.toLowerCase().trim()}
                                            maxLength="10"
                                            placeholder="Ingrese link de la categoria"
                                        ></input>
                                    </div>
                   
                                </Col>


                            </Row>
                            <br />
                            <Row>

                            <Col md="6" lg="6" sm="12">
                                    <Label >
                                        Imagen Del Company
                                    </Label>
                                    <input
                                        className="form-control center input-bordered"
                                        name="image"
                                        id="validationCustom01"
                                        type="file"
                                        onChange={handleImage}
                                    ></input>
                                    <div className="card-body text-right">

                                    <img src={`https://lordon.com.mx:81/${companyInput.image}`} width="100px" alt={companyInput.name}/>

                                    </div>
                                
                                </Col>

                                <Col md="6" lg="6" sm="12">
                                    <Label >
                                        Categoría
                                    </Label>
                                    <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon">
                                                            <i className="fa fa-bars prefix"></i>
                                                        </span>
                                                    </div>
                                                    <select
                                                        className="form-control "
                                                        name="id_category"
                                                        id="exampleFormControlSelect1"
                                                        onChange={handleInput}
                                                        value={companyInput.id_category}>
                                                        <option key={1} value="true">Selecciona Company</option>
                                                        {
                                                            categorylist.map( (item) => {
                                                                return(
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                
                                </Col>

                            </Row>
                            <br /> */}

                            (Ingles)
                            <Row>
                                <Col md="12" lg="12" sm="12">
                                    <Label>
                                        {/* ¿Qué hacemos? */}
                                        Our core business
                                    </Label>

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon">
                                                <i className="fa fa-bars prefix"></i>
                                            </span>
                                        </div>

                                        <textarea
                                            className="form-control"
                                            name="bussiness_en"
                                            id="validationCustom01"
                                            type="text"
                                            onChange={handleInput}
                                            value={companyInput.bussiness_en}
                                            maxLength="500"
                                            
                                        ></textarea>
                                    </div>
                                    

                                </Col>

                                <Col md="12" lg="12" sm="12">
                                    <Label >
                                        Mision
                                    </Label>

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon">
                                                <i className="fa fa-random prefix"></i>
                                            </span>
                                        </div>


                                        <textarea
                                            className="form-control"
                                            name="mision_en"
                                            id="validationCustom01"
                                            type="text"
                                            onChange={handleInput}
                                            value={companyInput.mision_en}
                                            maxLength="500"
                                            
                                        ></textarea>
                                    </div>

                                </Col>

                                <Col md="12" lg="12" sm="12">
                                    <Label >
                                        Vision
                                    </Label>

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon">
                                                <i className="fa fa-random prefix"></i>
                                            </span>
                                        </div>


                                        <textarea
                                            className="form-control"
                                            name="vision_en"
                                            id="validationCustom01"
                                            type="text"
                                            onChange={handleInput}
                                            value={companyInput.vision_en}
                                            maxLength="500"
                                            
                                        ></textarea>
                                    </div>

                                </Col>

                                <Col md="12" lg="12" sm="12">
                                    <Label >
                                        Values
                                    </Label>

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon">
                                                <i className="fa fa-random prefix"></i>
                                            </span>
                                        </div>


                                        <textarea
                                            className="form-control"
                                            name="values_en"
                                            id="validationCustom01"
                                            type="text"
                                            onChange={handleInput}
                                            value={companyInput.values_en}
                                            maxLength="500"
                                            
                                        ></textarea>
                                    </div>

                                </Col>


                            </Row>
                            <br />

                            (Imagenes)
                            <Row>
                                <Col md="6" lg="6" sm="12">
                                    <Label >
                                        ¿Qué hacemos?
                                    </Label>
                                    <input

                                        className="form-control  center input-bordered"
                                        name="image_bussiness"
                                        id="validationCustom01"
                                        type="file"
                                        onChange={handleImage}
                                    ></input>
                                    <div className="card-body text-right">
                                        <img src={`https://lordon.com.mx:81/${companyInput.image_bussiness}`} width="100px" alt={companyInput.image_bussiness}/>
                                    </div>
                                </Col>

                                <Col md="6" lg="6" sm="12">
                                    <Label >
                                        Mision y Vision
                                    </Label>
                                    <input

                                        className="form-control  center input-bordered"
                                        name="image_mision"
                                        id="validationCustom01"
                                        type="file"
                                        onChange={handleImage2}
                                    ></input>
                                    <div className="card-body text-right">
                                        <img src={`https://lordon.com.mx:81/${companyInput.image_mision}`} width="100px" alt={companyInput.image_mision}/>
                                    </div>
                                </Col>
                                
                                {/* <Col md="6" lg="6" sm="12">
                                    <Label >
                                        Imagen Vision
                                    </Label>
                                    <input

                                        className="form-control  center input-bordered"
                                        name="image3"
                                        id="validationCustom01"
                                        type="file"
                                        onChange={handleImage3}
                                    ></input>
                                    
                                </Col> */}

                                <Col md="6" lg="6" sm="12">
                                    <Label >
                                        Valores
                                    </Label>
                                    <input

                                        className="form-control  center input-bordered"
                                        name="image_values"
                                        id="validationCustom01"
                                        type="file"
                                        onChange={handleImage4}
                                    ></input>
                                    <div className="card-body text-right">
                                        <img src={`https://lordon.com.mx:81/${companyInput.image_values}`} width="100px" alt={companyInput.image_values}/>
                                    </div>
                                </Col>

                                

                            </Row>
                            <br />

                            <div className="offset-xl-5 offset-sm-4">
                            <Button className="btn btn-solid" type="submit"
                                            >
                                                Actualizar
                                            </Button>
                            </div>
                        </Form>

                    </CardBody>
                </Card>
            </Col>
            </TabPane>
            </Col>
        </Row>
        </section>
    )

}

export default UpdateCompany;